import React, { Component } from "react";
import "./ExhibitionList.sass";
import toBrake from "../lib/toBrake";

export function openExhibition({ title, url, width = 800, height = 600 }) {
  const { width: sw, height: sh } = window.screen;
  let w = window.open(
    url,
    title,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,rel=noopener,width=${width},height=${height},top=${
      sh / 2 - height / 2
    },left=${sw / 2 - width / 2}`
  );
  w.opener = null; // Try make to work mozilla hubs
}

function ExhibitionItem(props) {
  return (
    <li onClick={() => openExhibition(props)}>
      <div className="exhibition-title">{toBrake(props.title)}</div>
      <div className="exhibition-description">{toBrake(props.description)}</div>
    </li>
  );
}

class ExhibitionList extends Component {
  state = {
    exhibitions: [],
  };

  intervalId = null;

  getExhibitions = () => {
    fetch("Exhibition.json", { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => this.setState({ exhibitions: data["exhibitions"] }));
  };

  componentDidMount() {
    this.getExhibitions();
    this.intervalId = setInterval(this.getExhibitions, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { exhibitions } = this.state;
    const { backgroundColor } = this.props;
    return (
      <ul style={{ backgroundColor }} className="exhibition-list">
        {exhibitions.map((i, k) => (
          <ExhibitionItem key={k} {...i} />
        ))}
      </ul>
    );
  }
}

export default ExhibitionList;
