import React, { Component } from "react";
import "./EventSchedule.sass";
import toBrake from "../lib/toBrake";
import Parser from "html-react-parser";

function ScheduleItem(props) {
  if (props.title) {
    return (
      <li className="list-item" onClick={props.onClick}>
        {Parser(props.title)}
      </li>
    );
  } else if (props.section) {
    return (
      <li className="list-section" onClick={props.onClick}>
        {toBrake(props.section)}
      </li>
    );
  }
}

class EventSchedule extends Component {
  state = {
    schedule: [],
    processed: false,
  };

  intervalId = null;

  getSchedule = () => {
    return fetch("Schedule.json", { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ schedule: data["schedule"] }, () => {
          const { schedule, processed } = this.state;
          if (!processed && schedule.length > 0) {
            const index = schedule.findIndex(
              (item) => !!item.time || item.time === 0
            );
            this.setState(
              { processed: true },
              () => this.props && this.props.onSelect(schedule[index])
            );
          }
        });
      });
  };

  componentDidMount() {
    this.getSchedule();
    this.intervalId = setInterval(this.getSchedule, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { schedule } = this.state;
    const { onSelect } = this.props;
    const { backgroundColor } = this.props;
    return (
      <ul style={{ backgroundColor }} className="schedule-list">
        {schedule.map((i, k) => (
          <ScheduleItem key={k} onClick={() => onSelect(i)} {...i} />
        ))}
      </ul>
    );
  }
}

export default EventSchedule;
